.blog-image {
  object-fit: contain !important;
}

.thumbnail-image {
  object-position: center bottom !important;
}

.topic-channel-image {
  &[data-index='02'] {
    object-position: center bottom !important;
  }

  &[data-index='03'] {
    object-position: center bottom !important;
  }

  &[data-index='05'] {
    object-position: center bottom !important;
  }
}
