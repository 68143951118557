@import 'css/mixins.scss';

.home-hero-title {
  font-size: 68px !important;

  @include media('<tablet') {
    font-size: 40px !important;
  }
}

.home-hero-copy {
  --padding-right: 12px;
}
