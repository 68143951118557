@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.title {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 40px;
  color: var($--color);
  margin-bottom: 36px;
}
