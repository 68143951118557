@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.quote {
  position: relative;
  border-left: 4px solid map-deep-get($colors, 'gray', '200');
  padding: 15px 0 15px 40px;
  margin-bottom: 32px;

  .quote-symbol {
    position: absolute;
    top: -40px;
    left: 5px;
    z-index: -1;
    font-size: 200px;
    color: map-deep-get($colors, 'gray', '100');
  }

  .quote-text {
    font-weight: 500;
    font-size: 17px;
    font-style: italic;
    color: map-deep-get($colors, 'gray', '600');
    line-height: 32px;
    margin-bottom: 24px;
  }

  .quote-attribution-name {
    font-weight: 600;
    font-size: 17px;
    color: map-deep-get($colors, 'gray', '700');
  }

  .quote-attribution-title {
    font-weight: 500;
    font-size: 15px;
    color: map-deep-get($colors, 'gray', '500');
    margin-top: 8px;
  }

  .quote-attribution-source {
    margin-top: 8px;
    font-size: 12px;
  }
}
