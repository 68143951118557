@import 'css/themes.scss';
@import 'css/mixins.scss';

$copy-gap-xsmall: 6px;
$copy-gap-small: 8px;
$copy-gap-normal: 12px;
$copy-gap-medium: 16px;
$copy-gap-large: 24px;
$copy-gap-xlarge: 64px;

.copy,
.copy.section {
  display: grid;

  --padding-left: #{$copy-gap-normal};
  --padding-right: #{$copy-gap-normal};
  --copy-row-gap: #{$copy-gap-medium};

  row-gap: var(--copy-row-gap);
  grid-template-columns: minmax(0, 1fr);

  @include media('>=tablet') {
    --copy-row-gap: #{$copy-gap-large};
    --padding-right: #{$copy-gap-xlarge};
  }

  @include media('>=desktop') {
    --copy-row-gap: #{$copy-gap-large};
  }

  & > .header {
    position: relative;
    padding: 0 var(--padding-right) 0 var(--padding-left);
    display: grid;
    row-gap: var(--copy-row-gap);

    & > .caption {
      font-family: map-deep-get($fonts, 'body');
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: var($--accent-color);
    }

    & > .title {
      font-family: map-deep-get($fonts, 'header');
      font-weight: 600;
      font-size: 40px;
      color: var($--color);
    }
  }

  & > .body {
    padding: 0 var(--padding-right) 0 var(--padding-left);
    font-family: map-deep-get($fonts, 'body');
    font-weight: 500;
    font-size: 18px;
    color: var($--body-color);

    & > p {
      line-height: 28px;
      letter-spacing: 0.2px;

      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }
  }

  & > .footer {
    padding: 0 var(--padding-right) 0 var(--padding-left);
  }
}

.copy.subsection {
  & > .header {
    & > .title {
      font-size: 26px;
    }
  }
}

.copy.detail {
  --padding-right: #{$copy-gap-large};
  --padding-left: #{$copy-gap-large};

  @include media('>=tablet') {
    --copy-row-gap: #{$copy-gap-small};
  }

  @include media('>=desktop') {
    --copy-row-gap: #{$copy-gap-normal};
  }

  & > .header {
    position: relative;

    & > .chevron {
      position: absolute;
      top: 3px;
      left: 0;
      height: 14px;
      color: var($--accent-color);
    }

    & > .icon {
      color: var($--accent-color);
    }

    & > .title {
      font-size: 17px;
    }
  }

  & > .body {
    font-size: 16px;
    letter-spacing: 0.3px;

    & > p { /* stylelint-disable-line no-descending-specificity */
      line-height: 20px;
    }
  }
}

.copy.accent {
  & > .caption { /* stylelint-disable-line no-descending-specificity */
    color: var($--color);
  }

  & > .header {
    & > .title {
      color: var($--accent-color);
    }
  }
}

.copy.hero {
  padding: 60px 0 40px 0;

  & > .header {
    & > .title {
      font-size: 55px;

      @include media('<tablet') {
        font-size: 50px;
      }
    }
  }
}
