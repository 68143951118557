@import 'css/mixins.scss';

$column-gap-xsmall: 16px;
$column-gap-small: 24px;
$column-gap-normal: 32px;
$column-gap-medium: 48px;
$column-gap-large: 64px;
$column-gap-xlarge: 96px;

.column {
  display: grid;
  align-items: flex-start;

  --column-row-gap: #{$column-gap-normal};

  row-gap: var(--column-row-gap);

  & > * {
    min-width: 1px;
  }

  &.align-center {
    align-items: center;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  @include media('>=tablet') {
    --column-row-gap: #{$column-gap-medium};
  }

  @include media('>=desktop') {
    --column-row-gap: #{$column-gap-large};
  }
}

.column[data-columns='1,1,1,1'],
.column[data-columns='2,1,0'],
.column[data-columns='2,1,1'],
.column[data-columns='2,2'] {
  @include media('>=tablet') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.column[data-columns='1,1,1,1'] {
  @include media('>=desktop') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.column[data-columns='1,1'] {
  @include media('>=desktop') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.column[data-columns='1,3'] {
  @include media('>=desktop') {
    grid-template-columns: 1fr minmax(0, 3fr);
  }

  @include media('<=desktop') {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

.column[data-columns='3,1'] {
  @include media('>=desktop') {
    grid-template-columns: 3fr 1fr;
  }
}

.column[data-columns='2,1,1'] {
  @include media('>=desktop') {
    grid-template-columns: 2fr repeat(2, 1fr);
  }

  @include media('>=tablet', '<=desktop') {
    grid-area: 1 / span 2;
  }
}

.column[data-columns='1,1,2'] {
  @include media('>=desktop') {
    grid-template-columns: repeat(2, 1fr) 2fr;
  }
}

.column[data-columns='2,1,0'] {
  @include media('>=desktop') {
    grid-template-columns: 2fr 1fr 1fr;
  }
}

.column[data-columns='1,2,1'] {
  @include media('>=desktop') {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

.column[data-columns='1'] {
  @include media('>=tablet', '<=desktop') {
    grid-template-columns: 1fr;
  }
}
