@import 'css/variables.scss';
@import 'css/themes.scss';

.cta {
  display: inline-block;
  padding: 7px 0 9px 0;
  border-radius: 16px;
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: var($--button-color);
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: map-deep-get($transitions, 'hover');

  &:not(:first-of-type) {
    margin-left: 16px;
  }

  &:hover {
    color: var($--button-hover-color);
  }
}

// ARROW
.cta.has-arrow {
  padding-right: 12px;

  .arrow {
    margin-left: 4px;
  }
}

// VARIANT
.cta.button {
  padding-left: 14px;
  padding-right: 14px;
  background-color: var($--button-color);
  color: var($--knockout-color);
  transition-property: background-color;

  &:hover {
    background-color: var($--button-hover-color);
  }
}

.cta svg {
  position: relative;
  top: 3px;
}
