@import 'css/themes.scss';
@import 'css/mixins.scss';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var($--icon-color);
  background-color: var($--icon-background-color);

  & > * {
    height: 55%;
    width: 55%;
  }
}

.variant-square {
  border-radius: 7px;
}

.variant-circle {
  border-radius: 50%;
}

.size-sm {
  height: 24px;
  width: 24px;
}

.size-md {
  height: 36px;
  width: 36px;
}

.size-lg {
  height: 48px;
  width: 48px;
}

.size-xl {
  height: 64px;
  width: 64px;
}
