@import 'css/themes.scss';

.banner {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px 10px 8px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.25);
  color: var($--color);
}

.text {
  margin: 0 6px 0 4px;
}

.arrow {
  margin-bottom: -2px;
}
