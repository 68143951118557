@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.tag {
  font-weight: 600;
  font-size: 0.7em;
  background-color: var($--button-color);
  color: var($--knockout-color);
  padding: 0.2em 0.6em;
  margin: 0 0.5em;
  border-radius: 1em;
}
