@import 'css/themes.scss';

.flavor-1 {
  fill: var($--flavor-1-color);
}

.flavor-2 {
  fill: var($--flavor-2-color);
}

.flavor-3 {
  fill: var($--flavor-3-color);
}

.flavor-4 {
  fill: var($--flavor-4-color);
}

.flavor-5 {
  fill: var($--flavor-5-color);
}

.flavor-6 {
  fill: var($--flavor-6-color);
}
