@import 'css/mixins.scss';

.row {
  display: grid;
  grid: auto / minmax(0, 1fr);
  row-gap: 24px;
  column-gap: 16px;
  align-items: flex-start;

  & > * {
    margin-bottom: 0 !important;
  }
}

.row[data-columns='2'] {
  @include media('>=desktop') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.row[data-columns='3'] {
  @include media('>=desktop') {
    grid-template-columns: repeat(3, 1fr);
  }
}

.row[data-columns='4'] {
  @include media('>=tablet') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('>=desktop') {
    grid-template-columns: repeat(4, 1fr);
  }
}
