@import 'css/variables.scss';
@import 'css/themes.scss';

.item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 4px 0;
}

.icon {
  flex-shrink: 0;
  height: 14px;
  width: 14px;
  margin-top: 3px;
  margin-right: 8px;
  color: var($--accent-color);
}

.text {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.2px;
  color: var($--body-color);
}
