@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

$padding: 30px;

.hero {
  min-height: 500px;
  overflow: hidden;
  padding: $padding;
  background: rgba(255, 255, 255, 0.4);
  background-clip: padding-box;
  border-radius: 40px;
  border: 10px solid rgba(255, 255, 255, 0.6);

  @include media('>=tablet') {
    min-height: 450px;
  }

  @include media('>=desktop') {
    min-height: 400px;
  }
}
