@import-normalize;

@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';
@import "~@fontsource/lato/300.css";
@import "~@fontsource/lato/400.css";
@import "~@fontsource/lato/400-italic.css";
@import "~@fontsource/lato/700.css";
@import "~@fontsource/lato/900.css";
@import "~@fontsource/source-code-pro/400.css";
@import "~@fontsource/source-code-pro/700.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: map-deep-get($fonts, 'body');
  font-weight: 500;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include spread-map($theme-white);
}

blockquote,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  margin: 0;
}

a {
  text-decoration: none;
}

table {
  @include media('<tablet') {
    // Fix for tables extending beyond container on mobile
    table-layout: fixed;
    word-break: break-word;
    width: 100%;
  }
}

.slick-prev::before,
.slick-next::before {
  color: black;
}
