@import 'css/variables.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

$hover-spacer: 20px;

.menu {
  position: absolute;
  top: calc(100% - #{$hover-spacer});
  padding-top: $hover-spacer;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: map-deep-get($transitions, 'fadeIn');
  transition-property: opacity;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

.card {
  --section-1-width: 200px;
  --section-2-width: 200px;

  display: flex;
  align-items: stretch;
  border-radius: 6px;

  &.vertical {
    flex-direction: column;
  }

  @include media('<desktop') {
    flex-direction: column;
  }
}

.section {
  $section-padding: 32px;

  padding: $section-padding;
  flex-shrink: 0;

  &:nth-child(1) {
    flex: 1;
    min-width: calc(var(--section-1-width) + 2 * #{$section-padding});
    background-color: map-deep-get($colors, 'white');
  }

  &:nth-child(2) {
    min-width: calc(var(--section-2-width) + 2 * #{$section-padding});
    background-color: map-deep-get($colors, 'gray', '50');
  }
}

.subsection {
  &:not(:first-child) {
    margin-top: 24px;
  }

  .header {
    font-family: map-deep-get($fonts, 'body');
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: map-deep-get($colors, 'gray', '400');
    margin-bottom: 12px;
  }

  li > div > span:nth-child(1) {
    font-size: 15px !important;
  }

  li > div > span:nth-child(2) {
    font-size: 14px !important;
    color: map-deep-get($colors, 'gray', '500');
  }
}

/**
 * Card-specific stylings
 */
.card.product {
  --section-1-width: 250px;
  --section-2-width: 250px;
}

.card.resources {
  --section-1-width: 325px;
  --section-2-width: 275px;

  .section:nth-child(2) {
    li > div > span {
      max-width: var(--section-2-width);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
