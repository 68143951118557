@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.card {
  cursor: default;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 12px 2px rgba(map-deep-get($colors, 'gray', '600'), 0.25), 0 2px 6px -4px rgba(map-deep-get($colors, 'gray', '600'), 0.3);

  & > img {
    display: block;
  }
}
