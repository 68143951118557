.map {
  width: 100%;
  height: 500px;
  border: 0;
  filter: grayscale(0.8);

  iframe {
    height: 100%;
    width: 100%;
  }
}
