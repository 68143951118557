@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.outer {
  position: relative;
  z-index: 10;
}

.outer.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: none;
}

.outer.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.container {
  position: relative;
  max-width: calc(#{map-deep-get($spacing, 'column-padding')} * 2 + #{map-deep-get($spacing, 'layout-width')});
  margin: 0 auto;
  padding: 0 map-deep-get($spacing, 'column-padding');
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 76px;
  padding: 12px map-deep-get($spacing, 'column-padding');

  @include media('<tablet') {
    padding: 12px 0;
  }
}

.logo-link {
  text-decoration: none;
  display: block;
  outline: none;
  max-width: 130px;

  @include media('>=tablet') {
    max-width: 170px;
  }
}

.nav,
.cta-nav {
  @include media('<tablet') {
    display: none;
  }
}

.mobile-nav {
  @include media('>=tablet') {
    display: none;
  }
}

.nav-items {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-item-link {
  padding: 10px 20px;
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: var($--nav-color);
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  transition: map-deep-get($transitions, 'hover');
  transition-property: color;

  &[aria-haspopup='true'] {
    cursor: default;
  }

  &:hover,
  &.active {
    color: var($--nav-hover-color);
  }
}
