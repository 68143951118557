@import 'css/themes.scss';
@import 'css/mixins.scss';

$padding: 30px;

.container {
  position: relative;
}

.hero {
  min-height: 500px;
  overflow: hidden;
  padding: $padding;
  background-clip: padding-box;
  background-color: var($--flavor-1-color);
  border-radius: 40px;
  opacity: 0.98;

  @include media('>=tablet') {
    min-height: 450px;
  }

  @include media('>=desktop') {
    min-height: 400px;
  }
}

.visualizer {
  color: var($--knockout-color);
}

.background {
  position: absolute;
  height: 115%;
  width: 115%;
  top: -5%;
  left: -5%;
  z-index: -10;

  @include media('<desktop') {
    display: none;
  }
}
