@import 'css/themes.scss';

.outer {
  width: 100%;
  margin: 48px 0;
}

.caption {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 32px;
  color: var($--body-color);
}
