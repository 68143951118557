@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.outer {
  overflow-x: scroll;
  overscroll-behavior-x: none;
  font-family: map-deep-get($fonts, 'body');
  font-size: 15px;
  color: var($--color);
}

.wrapper {
  display: inline-block;
  min-width: 100%;
}

.no-results {
  padding: 20px;
}

.top-level-relationship {
  padding: 20px 0;
  border-bottom: 1px solid var($--border-color);

  &.correct {
    background-color: map-deep-get($colors, 'green', '100');
  }

  &.incorrect {
    background-color: map-deep-get($colors, 'red', '100');
  }
}

.element-wrapper {
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 70px;
  margin: 0 15px 0.5em 15px;
  white-space: nowrap;
}

.prepositions-wrapper {
  display: inline-block;
  position: relative;
  bottom: -0.95em;

  & table td {
    text-align: left;
    vertical-align: bottom;
  }
}
