@import 'css/variables.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.card {
  display: flex;
  align-items: stretch;
  box-shadow: 0 6px 12px 2px rgba(map-deep-get($colors, 'gray', '600'), 0.25), 0 2px 6px -4px rgba(map-deep-get($colors, 'gray', '600'), 0.3);
  border-radius: 10px;
  overflow: hidden;

  @include media('<tablet') {
    flex-direction: column;
  }
}

.card-image {
  flex: 0 0 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-body {
  display: grid;
  row-gap: 10px;
  padding: 20px;
}

.title {
  font-size: 35px !important;
}

.description {
  font-size: 15px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
