@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.element {
  font-family: map-deep-get($fonts, 'body');
  font-size: 14px;
  font-weight: 600;
  color: var($--color);
}

.element.hovered {
  // Highlight just itself when it's not a relationship element
  &:not(.relationship) .text-body {
    background-color: map-deep-get($colors, 'gray', '200');
  }

  // Highlight all the child elements when it's a relationship element
  &.relationship .element .text-body {
    background-color: map-deep-get($colors, 'gray', '200');
  }
}

.label {
  width: 100%;
  margin-top: 2px;
  padding-top: 4px;
  font-size: 13px;
  font-weight: 400;
  color: var($--body-color);
  cursor: default;

  .label-body[data-tip='true']:hover {
    cursor: context-menu;
  }
}

.tooltip {
  font-family: map-deep-get($fonts, 'body');
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: left !important;
  color: var($--color) !important;
  background-color: var($--background-color) !important;
  border: 1px solid var($--border-color) !important;
  opacity: 1 !important;

  &::after {
    border-bottom-color: var($--border-color) !important;
  }

  .tooltip-data {
    margin: 2px 0;
  }

  .tooltip-data-key {
    font-weight: 700;
  }
}

/**
 * Types
 */

.relationship .label {
  border-top: 1px solid map-deep-get($colors, 'green', '500');
}

.question .label {
  border-top: 1px solid map-deep-get($colors, 'pink', '500');
}

.qualifier .label {
  border-top: 1px solid solid map-deep-get($colors, 'purple', '500');
}

.subject .label {
  border-top: 1px solid map-deep-get($colors, 'blue', '500');
}

.predicate .label {
  border-top: 1px solid map-deep-get($colors, 'yellow', '500');
}

.object .label {
  border-top: 1px solid map-deep-get($colors, 'blue', '500');
}

.argument .label {
  border-top: 1px solid map-deep-get($colors, 'blue', '500');
}

.qualified .label-object {
  border-top: 1px solid map-deep-get($colors, 'indigo', '500');
}

.preposition .label {
  border-top: 1px solid map-deep-get($colors, 'red', '500');
}
