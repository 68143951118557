@import 'css/mixins.scss';

.list {
  --columns: 1;

  margin: 0;
  padding: 0;
  list-style: none;

  &[data-columns] {
    display: grid;
    grid: auto / repeat(var(--columns), 1fr);
    gap: 4px 32px;
    margin: 4px 0;
  }

  &[data-columns='2'] {
    --columns: 2;

    @include media('<tablet') {
      --columns: 1;
    }
  }

  &[data-columns='3'] {
    --columns: 3;

    @include media('<tablet') {
      --columns: 1;
    }
  }

  &[data-columns='4'] {
    --columns: 4;

    @include media('<tablet') {
      --columns: 2;
    }
  }
}
