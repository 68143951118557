@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.visualizer {
  color: var($--color);
}

.header {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.sentence,
.fact {
  font-family: map-deep-get($fonts, 'code');
  font-size: 15px;
  line-height: 30px;

  @include media('<tablet') {
    font-size: 14px;
    line-height: 28px;
  }
}

.sentence {
  margin-bottom: 40px;

  .sentence-span {
    opacity: 1;
    transition: 1s ease-in-out;
    transition-property: background-color, color, opacity;
  }
}

.visualizer.has-active-fact .sentence .sentence-span {
  opacity: 0.5;
}

/**
 * Colorings for the spans
 */
.flavor-1 {
  --active-span-background-color: #{map-deep-get($colors, 'logo', '100')};
  --active-span-color: #{map-deep-get($colors, 'black')};
}

.flavor-2 {
  --active-span-background-color: #{map-deep-get($colors, 'logo', '400')};
  --active-span-color: #{map-deep-get($colors, 'white')};
}

.flavor-3 {
  --active-span-background-color: #{map-deep-get($colors, 'logo', '600')};
  --active-span-color: #{map-deep-get($colors, 'white')};
}

.flavor-4 {
  --active-span-background-color: #{map-deep-get($colors, 'logo', '200')};
  --active-span-color: #{map-deep-get($colors, 'black')};
}

.flavor-5 {
  --active-span-background-color: #{map-deep-get($colors, 'logo', '500')};
  --active-span-color: #{map-deep-get($colors, 'white')};
}

.flavor-6 {
  --active-span-background-color: #{map-deep-get($colors, 'logo', '300')};
  --active-span-color: #{map-deep-get($colors, 'black')};
}

@for $i from 0 through 50 {
  .sentence.span-#{$i}-active .sentence-span[data-index="#{$i}"] {
    background-color: var(--active-span-background-color);
    color: var(--active-span-color);
    opacity: 1;
  }
}
