@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.footer-layout {
  display: grid;
  grid: auto / repeat(2, 1fr);
  row-gap: 40px;
  color: var($--color);

  @include media('>=tablet') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.column {
  display: grid;
  row-gap: 20px;

  @include media('>=tablet') {
    grid: min-content / auto;
  }
}

.logo-column {
  @include media('>=tablet') {
    grid-template-rows: auto min-content;
  }

  @include media('<tablet') {
    grid-template-rows: auto;
    grid-area: auto / span 2;
    row-gap: 8px;
  }
}

.resources-column {
  @include media('<tablet') {
    grid: inherit;
    grid-area: auto / span 2;
  }
}

.section {
  padding: 0 16px;
}

.section-header {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.2px;
  color: var($--color);
}

.logo-link {
  text-decoration: none;
  display: block;
  outline: none;
  max-width: 40px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-item {
  margin: 6px 0;

  & a {
    font-family: map-deep-get($fonts, 'body');
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: var($--footer-color);
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
    outline: none;
    transition: map-deep-get($transitions, 'hover');
    transition-property: color;

    &:hover {
      color: var($--footer-hover-color);
    }
  }
}
