@import 'css/mixins.scss';

.container {
  position: relative;
}

.background {
  position: absolute;
  height: 140%;
  width: 140%;
  top: -20%;
  left: -20%;
  z-index: -10;

  @include media('<desktop') {
    display: none;
  }
}
