@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.card {
  display: grid;
  overflow: hidden;
  height: 600px;
  border: 1px solid map-deep-get($colors, 'gray', '100');

  @include media('>=tablet') {
    height: 400px;
    grid-template-columns: 60% 40%;
  }
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 250px;
  border-right: 1px solid map-deep-get($colors, 'gray', '100');
  transition: map-deep-get($transitions, 'hover');
  transition-property: filter;
}

.content {
  padding: 24px;
  background-color: map-deep-get($colors, 'gray', '100');
  transition: map-deep-get($transitions, 'hover');
  transition-property: background-color;

  .copy {
    height: 100%;
    grid-template-rows: auto auto 1fr;
  }

  .caption,
  .title,
  .body,
  .button {
    transition: map-deep-get($transitions, 'hover');
    transition-property: color;
  }

  .body {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    @include media('>=tablet') {
      -webkit-line-clamp: 8;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
  }

  .button {
    align-self: flex-end;
  }
}

.card:hover {
  .image {
    filter: grayscale(60%);
  }

  .content {
    background-color: map-deep-get($colors, 'gray', '50');
  }

  .caption,
  .title,
  .body,
  .button {
    color: var($--link-hover-color);
  }
}
