@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.outer {
  cursor: default;
  grid-row: span 1;
  position: relative;
  overflow: hidden;
  min-width: 100px;
  border-radius: 10px;
  color: var($--knockout-color);
  background-color: var($--button-color);
  box-shadow: 0 6px 12px 2px rgba(map-deep-get($colors, 'gray', '600'), 0.25), 0 2px 6px -4px rgba(map-deep-get($colors, 'gray', '600'), 0.3);
}

.outer.span-2 {
  grid-row: span 2;
}

.outer.span-3 {
  grid-row: span 3;
}

.outer.span-4 {
  grid-row: span 4;
}

.outer:nth-child(6n + 1) {
  background-color: var($--flavor-1-color);
}

.outer:nth-child(6n + 2) {
  background-color: var($--flavor-2-color);
}

.outer:nth-child(6n + 3) {
  background-color: var($--flavor-3-color);
}

.outer:nth-child(6n + 4) {
  background-color: var($--flavor-4-color);
}

.outer:nth-child(6n + 5) {
  background-color: var($--flavor-5-color);
}

.outer:nth-child(6n + 6) {
  background-color: var($--flavor-6-color);
}

.card {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 15px;
}

.icon {
  margin-right: 12px;
}
