@import 'css/themes.scss';
@import 'css/mixins.scss';

.theme-white {
  @include spread-map($theme-white);

  background-color: var($--background-color);
}

.theme-light {
  @include spread-map($theme-light);

  background-color: var($--background-color);
}

.theme-dark {
  @include spread-map($theme-dark);

  background-color: var($--background-color);
}

.flavor-midnight {
  @include spread-map($flavor-midnight);
}

.flavor-sunrise {
  @include spread-map($flavor-sunrise);
}

.flavor-oceanic {
  @include spread-map($flavor-oceanic);
}

.flavor-forest {
  @include spread-map($flavor-forest);
}

.flavor-blue {
  @include spread-map($flavor-blue);
}

.flavor-purple {
  @include spread-map($flavor-purple);
}

.flavor-green {
  @include spread-map($flavor-green);
}

.accent-midnight {
  @include spread-map($accent-midnight);
}

.accent-sunrise {
  @include spread-map($accent-sunrise);
}

.accent-oceanic {
  @include spread-map($accent-oceanic);
}

.accent-forest {
  @include spread-map($accent-forest);
}

.accent-blue {
  @include spread-map($accent-blue);
}

.accent-purple {
  @include spread-map($accent-purple);
}

.accent-green {
  @include spread-map($accent-green);
}

.accent-logo {
  @include spread-map($accent-logo);
}
