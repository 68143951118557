@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin: 4px 0;

  &.has-title {
    align-items: center;
  }
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: var($--accent-color);
}

.body {
  display: grid;
  row-gap: 4px;
  font-family: map-deep-get($fonts, 'body');
  letter-spacing: 0.2px;
}

.title {
  display: block;
  font-weight: 600;
  font-size: 17px;
  color: var($--color);
}

.text {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: var($--body-color);
}

.link {
  .icon,
  .icon > *,
  .title,
  .text {
    transition: map-deep-get($transitions, 'hover');
    transition-property: color, background-color;
  }

  &:hover {
    .title,
    .text,
    .icon > * {
      color: var($--nav-hover-color) !important;
    }

    .icon > div {
      background-color: var($--nav-hover-background-color) !important;
    }
  }
}
