@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.container {
  position: relative;
  width: 100%;
  font-family: map-deep-get($fonts, 'code');
  color: map-deep-get($colors, 'gray', '100');
}

.header {
  padding: 15px 20px;
  background-color: map-deep-get($colors, 'gray', '900');
  overflow-x: scroll;
}

.url {
  margin-right: 10px;
}

.method {
  user-select: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 4px;
  background-color: var($--button-color);
  color: var($--knockout-color);
}
