@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.section {
  position: relative;
}

.container {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.layout-container {
  position: relative;
  width: 100%;
  max-width: map-deep-get($spacing, 'layout-width');
  margin: 0 map-deep-get($spacing, 'column-padding');
}

.layout {
  --layout-padding-desktop: 120px;
  --layout-padding-tablet: 80px;
  --layout-padding-offset-upper: 1;
  --layout-padding-offset-lower: 1;

  &.padding-none {
    --layout-padding-desktop: 0;
    --layout-padding-tablet: 0;
  }

  &.padding-sm {
    --layout-padding-desktop: 40px;
    --layout-padding-tablet: 20px;
  }

  &.padding-lg {
    --layout-padding-desktop: 180px;
    --layout-padding-tablet: 120px;
  }

  &.offset-upper {
    --layout-padding-offset-lower: 2;
  }

  &.offset-lower {
    --layout-padding-offset-upper: 2;
  }

  padding: calc(var(--layout-padding-desktop) / var(--layout-padding-offset-upper)) 0 calc(var(--layout-padding-desktop) / var(--layout-padding-offset-lower)) 0;

  @include media('<tablet') {
    padding: calc(var(--layout-padding-tablet) / var(--layout-padding-offset-upper)) 0 calc(var(--layout-padding-tablet) / var(--layout-padding-offset-lower)) 0;
  }
}

.gradient {
  height: 82px;

  &.full {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border: none;
    height: 100%;
    width: 100%;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
