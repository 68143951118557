@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.response-data {
  $pad: 32px;

  margin-top: $pad;
  padding-top: $pad;
  border-top: 1px solid var($--border-color);
  font-family: map-deep-get($fonts, 'body');
  font-size: 18px;
}

.no-results {
  color: map-deep-get($colors, 'gray', '700');
}
