@import 'css/mixins.scss';

.grid {
  display: grid;
  gap: 24px;
  grid-auto-rows: auto;

  @include media('>=tablet') {
    gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
}
