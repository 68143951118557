@import 'css/variables.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: map-deep-get($spacing, 'column-padding');
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: map-deep-get($transitions, 'fadeIn');
  transition-property: opacity;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

.card {
  position: relative;
  padding: 30px 25px;
}

.section {
  &:not(:first-child) {
    margin-top: 32px;
  }
}

.header {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: map-deep-get($colors, 'gray', '400');
  margin-bottom: 12px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 25px;
  width: 25px;
  color: map-deep-get($colors, 'gray', '300');
}
