@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.link {
  cursor: pointer;
}

.decoration {
  padding-bottom: 0;
  color: var($--link-color);
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: var($--link-color);
  transition: map-deep-get($transitions, 'hover');
  transition-property: padding-bottom, color, border-color;

  &:hover {
    padding-bottom: 1px;
    color: var($--link-hover-color);
    border-color: var($--link-hover-color);
  }
}
