@import 'css/themes.scss';

.list {
  margin-top: 0;
  padding: 0;
}

.button {
  padding-left: 14px;
  padding-right: 14px;
}

.content {
  border: 1px solid var($--border-color);
}
