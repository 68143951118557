@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.subheader {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: var($--body-color);
  margin-top: 24px;
  margin-bottom: 12px;
}
