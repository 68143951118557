@import '~include-media/dist/include-media';

// Spreads a map into css form
// @param {Map} $map - Map
// @return {*} - Spread value
@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}
