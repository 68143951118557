@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.wrapper {
  overflow-x: scroll;
}

.table {
  border-collapse: collapse;
  font-family: map-deep-get($fonts, 'body');
  font-size: 15px;
  color: var($--color);

  td {
    border: 1px solid var($--border-color);
    padding: 4px 6px;
  }

  thead td {
    font-weight: 600;
  }
}
