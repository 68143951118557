@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';
@import 'css/mixins.scss';

.api-pricing {
  overflow-x: scroll;
}

.pricing-cards {
  @include media('>tablet') {
    margin-top: 40px;
  }

  .api-pricing-card,
  .solution-pricing-card {
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0 6px 12px 2px rgba(map-deep-get($colors, 'gray', '900'), 0.25), 0 2px 6px -4px rgba(map-deep-get($colors, 'gray', '900'), 0.3);
  }

  .api-pricing-card {
    background-color: map-deep-get($colors, 'logo', '600');

    @include media('>tablet') {
      position: relative;
      right: -20px;
      transform: scale(1.15);
    }
  }

  .solution-pricing-card {
    background-color: map-deep-get($colors, 'logo', '500');

    @include media('>tablet') {
      padding-left: 50px;
    }
  }
}

.api-pricing-table {
  border-collapse: collapse;
  min-width: 100%;
  font-size: 15px;
  color: var($--color);

  td {
    border: 1px solid var($--border-color);
    padding: 24px;

    &:not(:first-child) {
      text-align: center;
    }
  }

  td > span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: map-deep-get($colors, 'gray', '400');
  }

  thead {
    color: var($--accent-color);
    font-weight: 600;
    font-size: 20px;
  }

  tbody {
    font-size: 32px;

    td:first-child {
      font-size: 18px;
    }
  }
}
