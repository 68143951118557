@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.body {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 500;
  font-size: 17px;
  color: var($--body-color);
  line-height: 24px;
  margin-bottom: 24px;
}
