@import 'css/themes.scss';
@import 'css/mixins.scss';

.hero-icon {
  height: 100%;
  width: 100%;
  color: var($--accent-color);

  @include media('<tablet') {
    display: none;
  }
}
