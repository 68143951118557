@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.svg {
  display: block;
  width: 100%;
  height: 100%;
}

.text {
  fill: var($--logo-color);
}

.logo-600 {
  fill: map-deep-get($colors, 'logo', '600');
}

.logo-500 {
  fill: map-deep-get($colors, 'logo', '500');
}

.logo-400 {
  fill: map-deep-get($colors, 'logo', '400');
}

.logo-300 {
  fill: map-deep-get($colors, 'logo', '300');
}

.logo-200 {
  fill: map-deep-get($colors, 'logo', '200');
}

.logo-100 {
  fill: map-deep-get($colors, 'logo', '100');
}
