.slider {
  margin-bottom: 32px;
  width: 100%;
  height: 175px;
  position: relative;

  .slider-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    & a {
      display: block;
      text-align: center;
    }

    & img {
      display: inline-block;
      width: 80%;
      height: 150px;
      object-fit: cover;
      object-position: center top;
    }
  }
}
