@import 'css/themes.scss';

.header {
  font-weight: 600;
  font-size: 32px;
  color: var($--color);
  margin-bottom: 28px;

  &:not(:first-child) {
    margin-top: 64px;
  }
}
