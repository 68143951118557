@import 'css/variables.scss';
@import 'css/functions.scss';

.subheader {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.2px;
  color: map-deep-get($colors, 'gray', '700');
  margin-top: 36px;
  margin-bottom: 20px;
}
