@import 'css/variables.scss';
@import 'css/functions.scss';

// CSS VARIABLES
$--color: --color;
$--body-color: --body-color;
$--accent-color: --accent-color;
$--link-color: --link-color;
$--link-hover-color: --link-hover-color;
$--background-color: --background-color;
$--border-color: --border-color;
$--icon-color: --icon-color;
$--icon-background-color: --icon-background-color;
$--button-color: --button-color;
$--button-hover-color: --button-hover-color;
$--knockout-color: --knockout-color;
$--nav-color: --nav-color;
$--nav-hover-color: --nav-hover-color;
$--nav-hover-background-color: --nav-hover-background-color;
$--footer-color: --footer-color;
$--footer-hover-color: --footer-hover-color;
$--logo-color: --logo-color;

$--flavor-1-color: --flavor-1-color;
$--flavor-2-color: --flavor-2-color;
$--flavor-3-color: --flavor-3-color;
$--flavor-4-color: --flavor-4-color;
$--flavor-5-color: --flavor-5-color;
$--flavor-6-color: --flavor-6-color;
$--flavor-gradient-1-color: --flavor-gradient-1-color;
$--flavor-gradient-2-color: --flavor-gradient-2-color;
$--flavor-gradient-3-color: --flavor-gradient-3-color;
$--flavor-gradient-4-color: --flavor-gradient-4-color;

// THEMES
$theme-white: (
  $--color: map-deep-get($colors, 'gray', '800'),
  $--body-color: map-deep-get($colors, 'gray', '700'),
  $--accent-color: map-deep-get($colors, 'gray', '500'),
  $--link-color: map-deep-get($colors, 'gray', '700'),
  $--link-hover-color: map-deep-get($colors, 'gray', '500'),
  $--background-color: map-deep-get($colors, 'white'),
  $--border-color: map-deep-get($colors, 'gray', '300'),
  $--icon-color: map-deep-get($colors, 'gray', '700'),
  $--icon-background-color: map-deep-get($colors, 'gray', '100'),
  $--button-color: map-deep-get($colors, 'gray', '500'),
  $--button-hover-color: map-deep-get($colors, 'gray', '700'),
  $--knockout-color: map-deep-get($colors, 'white'),
  $--nav-color: map-deep-get($colors, 'gray', '700'),
  $--nav-hover-color: map-deep-get($colors, 'gray', '400'),
  $--nav-hover-background-color: map-deep-get($colors, 'gray', '100'),
  $--footer-color: map-deep-get($colors, 'gray', '600'),
  $--footer-hover-color: map-deep-get($colors, 'gray', '300'),
  $--logo-color: map-deep-get($colors, 'black'),
);

$theme-light: (
  $--background-color: map-deep-get($colors, 'gray', '100'),
);

$theme-dark: (
  $--color: map-deep-get($colors, 'gray', '50'),
  $--body-color: map-deep-get($colors, 'gray', '100'),
  $--accent-color: map-deep-get($colors, 'gray', '100'),
  $--link-color: map-deep-get($colors, 'gray', '50'),
  $--link-hover-color: map-deep-get($colors, 'gray', '200'),
  $--icon-color: map-deep-get($colors, 'gray', '100'),
  $--icon-background-color: map-deep-get($colors, 'gray', '400'),
  $--background-color: map-deep-get($colors, 'gray', '800'),
  $--border-color: map-deep-get($colors, 'gray', '500'),
  $--button-color: map-deep-get($colors, 'gray', '50'),
  $--button-hover-color: map-deep-get($colors, 'gray', '200'),
  $--knockout-color: map-deep-get($colors, 'black'),
  $--nav-color: map-deep-get($colors, 'gray', '100'),
  $--nav-hover-color: map-deep-get($colors, 'gray', '400'),
  $--nav-hover-background-color: map-deep-get($colors, 'gray', '700'),
  $--footer-color: map-deep-get($colors, 'gray', '200'),
  $--footer-hover-color: map-deep-get($colors, 'gray', '500'),
  $--logo-color: map-deep-get($colors, 'white'),
);

// FLAVOR
$flavor-midnight: (
  $--flavor-1-color: map-deep-get($colors, 'gray', '900'),
  $--flavor-2-color: map-deep-get($colors, 'logo', '400'),
  $--flavor-3-color: #009a55,
  $--flavor-4-color: #000086,
  $--flavor-5-color: #004995,
  $--flavor-6-color: map-deep-get($colors, 'logo', '600'),
  $--flavor-gradient-1-color: map-deep-get($colors, 'gray', '900'),
  $--flavor-gradient-2-color: #000086,
  $--flavor-gradient-3-color: #004995,
  $--flavor-gradient-4-color: #009a55,
);

$flavor-sunrise: (
  $--flavor-1-color: map-deep-get($colors, 'purple', '900'),
  $--flavor-2-color: #980077,
  $--flavor-3-color: map-deep-get($colors, 'purple', '600'),
  $--flavor-4-color: #db005d,
  $--flavor-5-color: map-deep-get($colors, 'purple', '300'),
  $--flavor-6-color: #fd6945,
  $--flavor-gradient-1-color: map-deep-get($colors, 'purple', '900'),
  $--flavor-gradient-2-color: #980077,
  $--flavor-gradient-3-color: #fd6945,
  $--flavor-gradient-4-color: #ffb343,
);

$flavor-oceanic: (
  $--flavor-1-color: map-deep-get($colors, 'blue', '900'),
  $--flavor-2-color: #0076b9,
  $--flavor-3-color: map-deep-get($colors, 'blue', '600'),
  $--flavor-4-color: #009fbc,
  $--flavor-5-color: map-deep-get($colors, 'blue', '300'),
  $--flavor-6-color: #00c5a2,
  $--flavor-gradient-1-color: map-deep-get($colors, 'blue', '900'),
  $--flavor-gradient-2-color: #0076b9,
  $--flavor-gradient-3-color: #00c5a2,
  $--flavor-gradient-4-color: #82e380,
);

$flavor-forest: (
  $--flavor-1-color: map-deep-get($colors, 'green', '900'),
  $--flavor-2-color: #2f4858,
  $--flavor-3-color: map-deep-get($colors, 'green', '700'),
  $--flavor-4-color: #007c77,
  $--flavor-5-color: #0e596c,
  $--flavor-6-color: #87d57e,
  $--flavor-gradient-1-color: map-deep-get($colors, 'green', '900'),
  $--flavor-gradient-2-color: #10495f,
  $--flavor-gradient-3-color: #16a98c,
  $--flavor-gradient-4-color: #87d57e,
);

$flavor-blue: (
  $--flavor-1-color: map-deep-get($colors, 'blue', '900'),
  $--flavor-2-color: map-deep-get($colors, 'blue', '800'),
  $--flavor-3-color: map-deep-get($colors, 'blue', '700'),
  $--flavor-4-color: map-deep-get($colors, 'blue', '600'),
  $--flavor-5-color: map-deep-get($colors, 'blue', '500'),
  $--flavor-6-color: map-deep-get($colors, 'blue', '400'),
  $--flavor-gradient-1-color: map-deep-get($colors, 'blue', '900'),
  $--flavor-gradient-2-color: map-deep-get($colors, 'blue', '700'),
  $--flavor-gradient-3-color: map-deep-get($colors, 'blue', '500'),
  $--flavor-gradient-4-color: map-deep-get($colors, 'blue', '300'),
);

$flavor-purple: (
  $--flavor-1-color: map-deep-get($colors, 'purple', '900'),
  $--flavor-2-color: map-deep-get($colors, 'purple', '800'),
  $--flavor-3-color: map-deep-get($colors, 'purple', '700'),
  $--flavor-4-color: map-deep-get($colors, 'purple', '600'),
  $--flavor-5-color: map-deep-get($colors, 'purple', '500'),
  $--flavor-6-color: map-deep-get($colors, 'purple', '400'),
  $--flavor-gradient-1-color: map-deep-get($colors, 'purple', '900'),
  $--flavor-gradient-2-color: map-deep-get($colors, 'purple', '700'),
  $--flavor-gradient-3-color: map-deep-get($colors, 'purple', '500'),
  $--flavor-gradient-4-color: map-deep-get($colors, 'purple', '300'),
);

$flavor-green: (
  $--flavor-1-color: map-deep-get($colors, 'green', '900'),
  $--flavor-2-color: map-deep-get($colors, 'green', '800'),
  $--flavor-3-color: map-deep-get($colors, 'green', '700'),
  $--flavor-4-color: map-deep-get($colors, 'green', '600'),
  $--flavor-5-color: map-deep-get($colors, 'green', '500'),
  $--flavor-6-color: map-deep-get($colors, 'green', '400'),
  $--flavor-gradient-1-color: map-deep-get($colors, 'green', '900'),
  $--flavor-gradient-2-color: map-deep-get($colors, 'green', '700'),
  $--flavor-gradient-3-color: map-deep-get($colors, 'green', '500'),
  $--flavor-gradient-4-color: map-deep-get($colors, 'green', '300'),
);

// ACCENT
$accent-midnight: (
  $--accent-color: #009a55,
  $--link-color: #009a55,
  $--button-color: #009a55,
  $--icon-color: map-deep-get($colors, 'gray', '900'),
  $--icon-background-color: map-deep-get($colors, 'gray', '100'),
);

$accent-sunrise: (
  $--accent-color: #fd6945,
  $--link-color: #fd6945,
  $--button-color: #fd6945,
  $--icon-color: #fd6945,
  $--icon-background-color: #fff0ec,
);

$accent-oceanic: (
  $--accent-color: #009fbc,
  $--link-color: #009fbc,
  $--button-color: #009fbc,
  $--icon-color: #0076b9,
  $--icon-background-color: #e6f1f8,
);

$accent-forest: (
  $--accent-color: #16a98c,
  $--link-color: #16a98c,
  $--button-color: #16a98c,
  $--icon-color: #007c77,
  $--icon-background-color: map-deep-get($colors, 'green', '50'),
);

$accent-blue: (
  $--accent-color: map-deep-get($colors, 'blue', '500'),
  $--link-color: map-deep-get($colors, 'blue', '500'),
  $--button-color: map-deep-get($colors, 'blue', '500'),
  $--icon-color: map-deep-get($colors, 'blue', '600'),
  $--icon-background-color: map-deep-get($colors, 'blue', '50'),
);

$accent-purple: (
  $--accent-color: map-deep-get($colors, 'purple', '500'),
  $--link-color: map-deep-get($colors, 'purple', '500'),
  $--button-color: map-deep-get($colors, 'purple', '500'),
  $--icon-color: map-deep-get($colors, 'purple', '600'),
  $--icon-background-color: map-deep-get($colors, 'purple', '50'),
);

$accent-green: (
  $--accent-color: map-deep-get($colors, 'green', '500'),
  $--link-color: map-deep-get($colors, 'green', '500'),
  $--button-color: map-deep-get($colors, 'green', '500'),
  $--icon-color: map-deep-get($colors, 'green', '600'),
  $--icon-background-color: map-deep-get($colors, 'green', '50'),
);

$accent-logo: (
  $--accent-color: map-deep-get($colors, 'logo', '400'),
  $--link-color: map-deep-get($colors, 'logo', '400'),
  $--button-color: map-deep-get($colors, 'logo', '400'),
  $--icon-color: map-deep-get($colors, 'logo', '600'),
  $--icon-background-color: map-deep-get($colors, 'purple', '50'),
);
