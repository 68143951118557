@import 'css/mixins.scss';

$row-gap-xsmall: 16px;
$row-gap-small: 24px;
$row-gap-normal: 32px;
$row-gap-medium: 48px;
$row-gap-large: 64px;
$row-gap-xlarge: 96px;

.row {
  display: grid;
  grid: auto / minmax(0, 1fr);

  --row-row-gap: #{$row-gap-normal};

  row-gap: var(--row-row-gap);

  @include media('>=tablet') {
    --row-row-gap: #{$row-gap-medium};
  }

  @include media('>=desktop') {
    --row-row-gap: #{$row-gap-large};
  }
}

.size-sm {
  --row-row-gap: #{$row-gap-xsmall};

  @include media('>=tablet') {
    --row-row-gap: #{$row-gap-small};
  }

  @include media('>=desktop') {
    --row-row-gap: #{$row-gap-normal};
  }
}

.size-lg {
  --row-row-gap: #{$row-gap-medium};

  @include media('>=tablet') {
    --row-row-gap: #{$row-gap-large};
  }

  @include media('>=desktop') {
    --row-row-gap: #{$row-gap-xlarge};
  }
}
