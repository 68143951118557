@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.table {
  margin-bottom: 24px;
  border-collapse: collapse;

  th {
    background-color: map-deep-get($colors, 'gray', '100');
    font-weight: 700;
  }

  th,
  td {
    padding: 6px 8px;
    vertical-align: top;
    text-align: left;
    border: 1px solid var($--border-color);
  }
}
