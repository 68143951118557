@import 'css/themes.scss';

.textarea {
  height: 150px;
  resize: vertical;
}

.response {
  border: 1px solid var($--border-color);
}
