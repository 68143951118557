@import 'css/variables.scss';
@import 'css/themes.scss';
@import 'css/functions.scss';

.outer {
  background: var($--flavor-gradient-1-color);
}

.highlighter {
  overscroll-behavior-x: none;
  cursor: text;
  padding: 18px 14px !important;
  background: var($--flavor-gradient-1-color) !important;
  mask-image:
    linear-gradient(
      90deg,
      rgba(0, 0, 0, 0),
      var($--flavor-gradient-1-color) calc(34px + 20px),
      var($--flavor-gradient-1-color) calc(100% - 20px),
      rgba(0, 0, 0, 0)
    );
}

.code {
  font-family: map-deep-get($fonts, 'code');
  font-size: 14px;
  line-height: 24px;
}

.line-number {
  padding-right: 16px;
}
