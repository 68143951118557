@import 'css/themes.scss';
@import 'css/mixins.scss';

$paragraph-margin: 18px;
$header-margin: 18px;

.updated {
  font-family: map-deep-get($fonts, 'body');
  font-size: 14px;
  font-weight: 500;
  color: var($--accent-color);
  margin-bottom: 32px;
}

.policy {
  font-family: map-deep-get($fonts, 'body');
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var($--body-color);
  padding: 0 12px;

  h1 {
    font-family: map-deep-get($fonts, 'header');
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 12px;
    color: var($--color);
  }

  // Table of contents
  ol.toc {
    padding-inline-start: 0;
    margin: 0;

    &:not(.continue) {
      counter-reset: tocitem;
    }

    li,
    li > a {
      color: var($--color);
      font-family: map-deep-get($fonts, 'header');
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;
    }

    li {
      display: block;
      position: relative;
      padding-left: 18px + $header-margin;
      margin-bottom: 8px;

      &::before {
        content: counter(tocitem) ". ";
        counter-increment: tocitem;
        position: absolute;
        left: 0;
      }
    }
  }

  // Headings enumerated list
  ol.headings {
    padding-inline-start: 0;
    margin: 0;

    &:not(.continue) {
      counter-reset: headingsitem;
    }

    & > li,
    & > li > h2 {
      font-family: map-deep-get($fonts, 'header');
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      color: var($--color);
    }

    & > li {
      display: block;
      position: relative;
      padding-left: 28px + $header-margin;
      margin-top: 36px;
      margin-bottom: 8px;

      &::before {
        content: counter(headingsitem) ". ";
        counter-increment: headingsitem;
        position: absolute;
        left: 0;
      }
    }

    & > li > h2 {
      display: inline-block;
    }
  }

  // Outer enumerated list
  ol.enumeration {
    list-style: none;
    padding: 0;

    &:not(.continue) {
      counter-reset: alphalist;
    }

    li {
      counter-increment: alphalist;
      margin-bottom: $paragraph-margin;

      &::before {
        content: "(" counter(alphalist, lower-alpha) ") ";
        width: 40px;
        display: inline-block;
      }
    }
  }

  // Inner enumerated list
  ol.sub-enumeration {
    list-style: none;
    margin-top: $paragraph-margin;
    padding: 0 60px;

    &:not(.continue) {
      counter-reset: romanlist;
    }

    li {
      counter-increment: romanlist;
      margin-bottom: $paragraph-margin;

      &::before {
        content: "(" counter(romanlist, lower-roman) ") ";
        width: 40px;
        display: inline-block;
      }
    }
  }

  // Body text
  p {
    margin-bottom: $paragraph-margin;
  }

  ul {
    margin: 0;
    padding-inline-start: 20px;
  }

  table {
    border-collapse: collapse;
    margin-bottom: $paragraph-margin;

    th {
      background-color: map-deep-get($colors, 'gray', '100');
      font-weight: 700;
    }

    th,
    td {
      padding: 10px 15px;
      vertical-align: top;
      text-align: left;
      border: 1px solid var($--border-color);
    }
  }

  .redline {
    color: map-deep-get($colors, 'red', '700');
  }

  .term {
    font-weight: 700;
  }

  .header {
    margin-right: $header-margin;
  }
}
