@import 'css/themes.scss';

.theme {
  stop-color: var($--background-color);
}

.fill {
  fill: var($--flavor-gradient-1-color);
}

.stop1 {
  stop-color: var($--flavor-gradient-1-color);
}

.stop2 {
  stop-color: var($--flavor-gradient-2-color);
}

.stop3 {
  stop-color: var($--flavor-gradient-3-color);
}

.stop4 {
  stop-color: var($--flavor-gradient-4-color);
}
