@import 'css/themes.scss';
@import 'css/mixins.scss';

.api-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media('<tablet') {
    display: none;
  }
}

.api-icon {
  height: 350px;
  width: 350px;
  border-radius: 20px;
  box-shadow: 0 6px 12px 2px rgba(map-deep-get($colors, 'gray', '600'), 0.25), 0 2px 6px -4px rgba(map-deep-get($colors, 'gray', '600'), 0.3);
}
